import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { subscriptionContext } from 'src/context'
import { useModal } from 'src/modules/modals'
import { Pane } from 'src/modules/billing'

export const UpgradeModal = () => {
  const { monthlyPlan, advMonthlyPlan } = useContext(subscriptionContext)
  const { setModal } = useModal()
  const navigate = useNavigate()

  const closeModal = () => {
    localStorage.setItem('sizle.lastSeenUpgradeModal', (new Date()).valueOf())
    setModal('')
  }

  const goToBilling = () => {
    navigate('../billing')
    closeModal()
  }

  return ReactDOM.createPortal(
    <Wrapper onClick={closeModal} name='welcome-back-modal'>
      <Popup>
        <Content onClick={(e) => e.stopPropagation()}>
          <Header>Welcome back! Need a team plan?</Header>
          <Heading>It's easy to change or modify your plan at any time</Heading>
          <Panes>
            <Pane activate={goToBilling} plan={monthlyPlan} />
            <Pane activate={goToBilling} plan={advMonthlyPlan} />
          </Panes>
          <CloseButton onClick={closeModal}>Close</CloseButton>
        </Content>
      </Popup>
    </Wrapper>,
    document.getElementById('root'))
}

const Wrapper = styled.div`
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.1s;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #0005;

  @media screen and (max-width: 1280px) {
    align-items: flex-start;
  }
  .billing-pane {
    margin-right: 10px;
    margin-left: 10px;
  }
`

const Popup = styled.div`
  z-index: 99;
  box-shadow: rgb(0 0 0 / 4%) 0px 0px 3px 3px;
`

const Content = styled.div`
  background: var(--background-highlight-color);
  color: var(--text-color);
  text-align: center;
  padding: 40px 40px 10px 40px;
  border-radius: 5px;

  @media screen and (max-width: 664px) {
    padding: 20px 0 10px 0;
  }
`

const Header = styled.h1`
  font-size: 31px;
  font-weight: 500;
  margin-bottom: 20px;
`

const Heading = styled.h3`
  margin-top: 5px;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 17px;

  @media screen and (max-width: 664px) {
    margin-bottom: 15px;
  }
`

const Panes = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`

const CloseButton = styled.button`
  padding-top: 20px;
  padding-bottom: 10px;
  font-weight: 500;
  color: #C7C7C7;
`
